import { Component, ElementRef, Input, Output, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { AppAnimationsService } from 'src/services/app-animations/app-animations.service';
import { AppCartService } from 'src/services/app-cart/app-cart.service';
import { AppEventsService } from 'src/services/app-events/app-events.service';
import { AppToastService } from 'src/services/app-toast/app-toast.service';
import { ConfigService } from 'src/services/config/config.service';
import { SharedDataService } from 'src/services/shared-data/shared-data.service';
import SwiperCore, { Pagination, Navigation, Scrollbar } from 'swiper';
import { SwiperComponent } from "swiper/angular";

SwiperCore.use([Pagination, Navigation, Scrollbar]);
@Component({
  selector: 'app-program-card-search',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class ProgramCardSearchComponent implements OnInit {
  @ViewChild('productRef', { static: false }) productRef: ElementRef
  @ViewChild('swiper', { static: false,  }) swiper?: SwiperComponent;

  @Input('data') data: any//product data
  public wishListFlagBool = false
  constructor(
    public config: ConfigService,
    public shared: SharedDataService,
    public navCtrl: NavController,
    public appEventsService: AppEventsService,
    public appCartService: AppCartService,
    public appToastService: AppToastService,
    public appAnimationsService: AppAnimationsService
  ) {
  }

  openProductPage() {
    this.shared.singleProductPageDataArray.push(this.data)
    this.navCtrl.navigateForward("/product-detail/" + this.data.product_id)
  }

  prev() {
    this.swiper.swiperRef.slidePrev(500);
  }

  next() {
    this.swiper.swiperRef.slideNext(500);
  }

  addRemoveWishProduct() {
  }

  addToCart() {
    if (this.data.product_type == 'variable') {
      this.openProductPage()
    }
    else {
      this.checkingProductStock()
    }
  }

  quantityNumber: number = 1
  enableOutOFStockButtonBool = false
  quantityMinus() {
    if (this.quantityNumber > 1) {
      this.quantityNumber--
      this.checkingProductStock()
    }
  }
  quantityPlus() {
    this.quantityNumber++
    this.checkingProductStock()
  }

  checkingProductStock() {
    this.appCartService.checkProductStock(
      this.data.product_id,
      this.data.product_type,
      null,
      this.quantityNumber).then((data: any) => {
        if (data.status == "outOfStock") {
          this.enableOutOFStockButtonBool = true
        }
        else if (data.status == "canAddToCart") {
          this.appCartService.addToCart(this.data.product_id, this.quantityNumber, null)
        }
        else if (data.status == "quantityIsLimited") {
          this.appToastService.toastError("Quantity is Limited")
          this.quantityNumber = data.stock
        }
      });
  }

  ngOnInit() {
          
  }
  public viewIsLoadedBool = false
  public backgroundIsAppliedBool = false
  ngAfterViewInit() {
    this.viewIsLoadedBool = true

  }
  ngDoCheck() {
    
  }
  getProductRef() {
    return this.productRef.nativeElement.querySelector('ion-card');
  }
  applyBackgroundColor() {
    let backGroundColorsArray = this.config.productCardColorsArray
    let card = this.getProductRef()
    card.style.backgroundColor = backGroundColorsArray[this.shared.productCardCounterNumber]
    this.shared.productCardCounterNumber++
    if (this.shared.productCardCounterNumber == backGroundColorsArray.length) this.shared.productCardCounterNumber = 0
  }

}
