import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PipesModule } from 'src/pipes/pipes.module';
import { ProgramModalPage } from './programs-modal.page';
import { ProgramCardSearchModule } from 'src/components/program-card-search/card.module';

const routes: Routes = [
  {
    path: '',
    component: ProgramModalPage
  }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        PipesModule
    ],
    exports: [
      ProgramCardSearchModule
    ],
    
    declarations: [ProgramModalPage]
})
export class ProgramModalPageModule { }
