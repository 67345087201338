import { Component, OnInit, ViewChild } from '@angular/core';
import { IonicSlides, ModalController, NavController } from '@ionic/angular';
import { SharedDataService } from 'src/services/shared-data/shared-data.service';
import { ConfigService } from 'src/services/config/config.service';
import { AppEventsService } from 'src/services/app-events/app-events.service';
import SwiperCore, { Autoplay, Keyboard, Pagination, Scrollbar, Zoom } from 'swiper';
import { SwiperComponent } from "swiper/angular";
SwiperCore.use([Autoplay, Keyboard, Pagination, Scrollbar, Zoom, IonicSlides]);
@Component({
  selector: 'app-intro',
  templateUrl: './intro.page.html',
  styleUrls: ['./intro.page.scss'],
})
export class IntroPage implements OnInit {


  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  // sliderConfig = {
  //   observer: true,
  //   observeParents: true
  // }

  public slidesArray = [
    { image: "assets/intro/intro-1.jfif", description: "university", description2: "made easy" },
  ];
  constructor(
    public navCtrl: NavController,
    public shared: SharedDataService,
    public config: ConfigService,
    public appEventsService: AppEventsService,
    public modalCtrl: ModalController
  ) {
  }

  swipeNext() {
    this.swiper.swiperRef.slideNext(100);
  }
  //close modal
  dismiss() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {

  }

}
