import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ConfigService } from 'src/services/config/config.service';
import { SharedDataService } from 'src/services/shared-data/shared-data.service';
import { ViewEncapsulation} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppHttpService } from 'src/services/app-http/app-http.service';
import SwiperCore, { Pagination } from 'swiper';
import { SwiperComponent } from "swiper/angular";

SwiperCore.use([Pagination]);
@Component({
  selector: 'app-program-card-details-uni',
  templateUrl: './program-card-details-uni.component.html',
  styleUrls: ['./program-card-details-uni.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgramCardDetailsUniComponent implements OnInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  @Input('data') data;
  @Input('brief') brief = '';
  @Input('university') university;
  @Input('wishListStatus') wishListStatus;
  @Input('id') id;
  programDetails = [];
  universityDetailsContent = '';
  @Output() onClickProduct = new EventEmitter();
  @Output() onClickWishIcon = new EventEmitter();

  constructor(
    public config: ConfigService,
    public shared: SharedDataService,
    public navCtrl: NavController,
    public activatedRoute: ActivatedRoute,
    public appHttpService: AppHttpService,
    public router: Router
  ) {

  }
  clickWishIcon() {
    this.onClickWishIcon.emit()
  }
  getHeartColor() {
    if (this.wishListStatus)
      return 'danger'
    else
      return 'medium'
  }
  openProductPage() {
    this.onClickProduct.emit();
  }

  goBack() {
    this.navCtrl.pop()
  }

  program(id) {
    this.router.navigate(['program-page-details/' + id])
  }
  ngOnInit() {
  }

  ApplyNow() {

  }
}
