import { Component, OnInit } from '@angular/core';
import { ConfigService } from '../../services/config/config.service';
import { SharedDataService } from 'src/services/shared-data/shared-data.service';
import { ModalController } from '@ionic/angular';
import { LoadingService } from 'src/services/loading/loading.service';
import { PrivacyPolicyPage } from '../modals/privacy-policy/privacy-policy.page';
import { TermServicesPage } from '../modals/term-services/term-services.page';
import { RefundPolicyPage } from '../modals/refund-policy/refund-policy.page';
import { AppCartService } from 'src/services/app-cart/app-cart.service';
import { AppHttpService } from 'src/services/app-http/app-http.service';

import { Browser } from '@capacitor/browser';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.page.html',
  styleUrls: ['./about-us.page.scss'],
})
export class AboutUsPage implements OnInit {

  constructor(
    public shared: SharedDataService,
    public config: ConfigService,
    public modalCtrl: ModalController,
    public loading: LoadingService,
    public appCartService: AppCartService,
    public appHttpService: AppHttpService,
  ) {
  }

  ngOnInit() {
    if (this.shared.aboutUs == "")
      this.getPageData()
  }
  getPageData() {
    // let url = "pages/1"
    // url += "?language_id=" + this.config.languageIdNumber
    // this.appHttpService.getHttp(url).then((data: any) => {
    //   this.shared.aboutUs = data.detail[0].description
    // })

    setTimeout(() => {
      this.shared.aboutUs = "<div class='container'> <div class='row'> <div class='col-lg-12'> <div class='row '> <div class='col-12 col-lg-6'> <h2 class='mb-3'>Mission</h2> <div class='subtitle-about'> Encourage students to create meaningful educational objectives and support them as they advance in their academic careers. By supporting students as they explore their interests, talents, and abilities, we also hope to inspire them to achieve their ultimate goal of graduating. </div> </div> <div class='col-12 col-lg-6'> <h2 class='mb-3'>Vision</h2> <div class='subtitle-about'> To become among the world's top educational consultants. Additionally, by opening up the possibility of studying abroad, make top-notch, cutting-edge education available to everyone. With the right direction and competent assistance, every goal is attainable. </div> <ul> <li> Teaching skills in public speaking. </li> <li> Caring. </li> <li> Commitment. </li> <li> Empowerment. </li> <li> Integrity. </li> <li> Respect. </li> </ul> </div> <div class='col-12 mt-3'> <h2 class='mb-3'>About us</h2> We serve as university advisors, assisting students in meeting their academic requirements,<br> choosing the appropriate institution, submitting an application, setting up lodging, and getting<br> ready for the admissions test. We believe that every student possesses the aptitudes and<br> competencies required for success.<br><br> Our advisors are available to support you as you choose your major and future while also<br> helping you navigate our academic environment.<br><br> Meet with one of our advisers if you need advice, support, a sounding board, or if you just need<br> someone to steer you in the right way!<br><br> </div> </div> </div> </div> </div>"
    }, 2000);
  }
  dismiss() {
    this.modalCtrl.dismiss()
  }
  async showModal(value) {
    if (value == 'privacyPolicy') {
      let modal = await this.modalCtrl.create({
        component: PrivacyPolicyPage
      });
      return await modal.present();
    }
    else if (value == 'termServices') {
      let modal = await this.modalCtrl.create({
        component: TermServicesPage
      });
      return await modal.present();
    }
    else {
      let modal = await this.modalCtrl.create({
        component: RefundPolicyPage
      });
      return await modal.present();
    }
  }
  async openSite() {
    this.loading.autoHide(2000);
    await Browser.open({ url: this.config.yourSiteUrlString });
  }

}
