import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { PipesModule } from 'src/pipes/pipes.module';
import { ProgramCardSearchComponent } from './card.component';
import { ProgramCardPageComponent } from '../program-card-page/program-card-page.component';
import { AnimationDirectiveModule } from 'src/directives/appAnimation/animationDirective.module';
import { AngularCountriesFlagsModule } from 'angular-countries-flags'
import {DividerModule} from 'primeng/divider';
import { ProgramCardDetailsUniComponent } from '../program-card-details-uni/program-card-details-uni.component';
import { SwiperModule } from 'swiper/angular';

@NgModule({
  declarations: [
    ProgramCardSearchComponent,
    ProgramCardDetailsUniComponent
  ],
  exports: [
    ProgramCardSearchComponent,
    ProgramCardDetailsUniComponent,
    SwiperModule
  ],
  imports: [
    IonicModule,
    CommonModule,
    PipesModule,
    AnimationDirectiveModule,
    AngularCountriesFlagsModule,
    DividerModule,
  ],
})
export class ProgramCardSearchModule { }
